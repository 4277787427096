<template>
  <li
    class="flex items-start mb-4"
    :class="small ? 'md:text-base md:mb-5' : 'md:text-lg md:mb-6'"
  >
    <img
      v-if="company === 'Humana'"
      class="mt-1"
      src="../../assets/img/FallsFree/check-mark-humana.svg"
    />

    <img
      v-else-if="company === 'Blue KC'"
      style="max-width: 42px"
      class="mt-1 mr-2"
      src="../../assets/img/FallsFree/check-mark-bluekc.png"
    />

    <img
      v-else-if="company === 'John Hancock'"
      style="max-width: 42px"
      class="mt-1 mr-2"
      src="../../assets/img/FallsFree/check-mark-jh.png"
    />

    <img
      v-else-if="company === 'CarePlus'"
      style="max-width: 42px"
      class="mt-1 mr-2"
      src="../../assets/img/FallsFree/check-mark-careplus.png"
    />

    <img
      v-else-if="company === 'DRCOG' || company === 'Sales Demo'"
      style="max-width: 42px"
      class="mt-1 mr-2"
      src="../../assets/img/FallsFree/check-mark-plum.svg"
    />

    <img
      v-else-if="company === 'Blue MI'"
      style="max-width: 42px"
      class="mt-1 mr-2"
      src="../../assets/img/FallsFree/check-mark-plum.svg"
    />

    <img
      v-else-if="company === 'ACC'"
      style="max-width: 42px"
      class="mr-2"
      src="../../assets/img/FallsFree/checkmark-acc.png"
    />

    <img
      v-else
      class="mt-0"
      style="color: white"
      src="../../assets/img/FallsFree/check-mark.svg"
    />

    <p class="ml-2 mt-0">
      {{ text }}
    </p>
  </li>
</template>

<script>
export default {
  props: {
    text: String,
    company: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

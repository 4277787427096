<template>
  <div v-if="getLogoPath()" class="image-container m-auto">
    <img
      :class="classes"
      class="m-auto md:max-w-full px-10 lg:px-0"
      :src="getLogoPath()"
    />
    <a
      :class="nymblLogoOnLeft ? 'image-link-left' : 'image-link-right'"
      href="https://nymblscience.com"
      target="_blank"
      rel="noopener"
    />
  </div>

  <a v-else href="https://nymblscience.com" target="_blank" rel="noopener">
    <img
      :class="classes"
      class="m-auto md:max-w-xs px-10 lg:px-0"
      :src="getNymblLogo()"
    />
  </a>
</template>

<script>
export default {
  props: {
    companyName: String,
    isFooter: Boolean,
  },
  computed: {
    classes() {
      const classes = [];

      if (this.companyName === "Humana") {
        classes.push("lg:my-6 md:max-w-md");
      }
      if (this.companyName === "Blue KC") {
        classes.push("lg:my-2 md:max-w-xs");
      }
      if (this.companyName === "Blue KC" && this.isFooter) {
        classes.push("lg:my-2 lg:max-w-xs");
      }
      if (this.isFooter) {
        classes.push("md:max-w-lg");
      }
      return classes;
    },
    nymblLogoOnLeft() {
      return this.companyName === "DRCOG";
    },
  },
  methods: {
    getLogoPath() {
      let img = null;
      if (this.companyName === "Humana" && !this.isFooter) {
        img = "HumanaLogo.png";
      }
      if (this.companyName === "Humana" && this.isFooter) {
        img = "2023_LOGO_HUMANA_NYMBL.png";
      }
      if (this.companyName === "CarePlus") {
        img = "2023_LOGO_CAREPLUS_NYMBL.png";
      }
      if (this.companyName === "Arvada Fire") {
        img = "AF-Nymbl-logo.png";
      }
      if (this.companyName === "DRCOG") {
        img = "2023_LOGO_DRCOG_NYMBL.png";
      }
      if (this.companyName === "Blue KC") {
        img = "2023_LOGO_BLUE KC MA_NYMBL.png";
      }
      if (this.companyName === "John Hancock") {
        img = "johnhancock-nymbl.logo.svg";
      }

      return img ? require("../../assets/img/FallsFree/" + img) : null;
    },
    getNymblLogo() {
      return require("../../assets/img/FallsFree/Nymbl-Logo.svg");
    },
  },
};
</script>
<style scoped>
.image-container {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}

.image-link-right {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: rgba(0, 0, 0, 0); /* Adjust as needed */
}
.image-link-left {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: rgba(0, 0, 0, 0); /* Adjust as needed */
}
</style>

const getCompanyName = () => {
  const location = window.location.host;
  const host = location.split(".")[0].toLowerCase();

  if (host === "humana") {
    return "Humana";
  }
  if (host === "co") {
    return "DRCOG";
  }
  if (host === "kc") {
    return "Blue KC";
  }
  if (host === "mi") {
    return "Blue MI";
  }
  if (host === "jh") {
    return "John Hancock";
  }
  if (host === "solera") {
    return "Blue Shield of California";
  }
  if (host === "tcare") {
    return "TCare";
  }
  if (host === "careplus") {
    return "CarePlus";
  }
  if (host === "nymbldemo") {
    return "Sales Demo";
  }

  // handle fallsfree.co.nz and test.fallsfree.co.nz
  if (location.includes("nz") && location.includes("fallsfree")) {
    return "ACC";
  }

  return "fallsfree";
};

export default getCompanyName;
